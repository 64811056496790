<template>
  <div>
    <div class="w-100 d-flex justify-content-between align-items-center px-2">
      <div class="d-flex justify-content-center w-100 my-2">
        <img :src="require('@/assets/images/logo/botanic.jpg')" alt="Logo" class="w-75"/>
      </div>

      <b-link>
        <b-button @click="$emit('closeMenu')"
                  variant="flat-primary"
                  class="btn-icon">
          <feather-icon
              class="d-block text-primary"
              icon="XIcon"
              size="20"/>
        </b-button>
      </b-link>
    </div>

    <!-- Hortilio Tools Pages-->
    <jaya-divider :thickness="1" class="pt-50" color="primary" text="OUTILS"/>

    <div
        v-for="(page, index) in hortilioToolPages"
        :key="index + mainTabs.length"
        :class="page.active ? 'jaya-menu-button-active' : ''"
        class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
        @click="goToPage(page.route)">
      <feather-icon
          :icon="page.icon + 'Icon'"
          class="mr-1"
          size="20"/>
      <span class="">{{ page.name }}</span>
      <div class="pl-50" v-if="page.new"><b-spinner small type="grow" variant="primary"/></div>
    </div>

    <!-- Hortilio Other Pages-->
    <jaya-divider :thickness="1" class="pt-50" color="secondary" text="AUTRES"/>

    <div
        v-for="(page, index) in hortilioOtherPages"
        :key="index + mainTabs.length + hortilioToolPages.length"
        :class="page.active ? 'jaya-menu-button-active' : ''"
        class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
        @click="goToPage(page.route)">
      <feather-icon
          :icon="page.icon + 'Icon'"
          class="mr-1"
          size="20"/>
      <span class="">{{ page.name }}</span>
    </div>

  </div>
</template>

<script>
import {BLink, BButton, BSpinner} from "bootstrap-vue"

export default {
  components: {
    BLink,
    BButton,
    BSpinner,
  },
  data() {
    return {
      localAuthority: null,
      localAuthorityActive: false,

      mainTabs: [
      ],
      hortilioToolPages: [
        {name: "Accueil", route: "jayaccueil", icon: "Home", active: false},
        {name: "Encyclopédie", route: "encyclopedie", icon: "BookOpen", active: false},
        {name: "Cultures associées", route: "interactions-explorer", icon: "RefreshCw", active: false},
        {name: "Biodiv' Quiz", route: "quiz", icon: "PenTool", active: false},
        {name: "Fiches pratiques", route: "knowledge-base", icon: "FileText", active: false},

      ],
      hortilioOtherPages: [
        {name: "À-propos", route: "about-us", icon: "Info", active: false},
      ],
    }
  },
  computed: {
    isAdministrator() {
      return this.$store.state.localAuthority.memberOf
    },
    getActiveLocalAuthorities() {
      let activeGarden = this.$store.state.garden.gardens.filter((g) => {
        return g.is_active
      })[0]
      return activeGarden.local_authorities_infos
    },
  },
  methods: {
    goToTab(hash) {
      for (let i = 0; i < this.hortilioToolPages.length; i++) {
        this.hortilioToolPages[i].active = false
      }
      for (let i = 0; i < this.hortilioOtherPages.length; i++) {
        this.hortilioOtherPages[i].active = false
      }
      for (let i = 0; i < this.mainTabs.length; i++) {
        this.mainTabs[i].hash === hash ? this.mainTabs[i].active = true : this.mainTabs[i].active = false
      }
      if (this.$route.hash !== hash) {
        this.$router.push({name: 'jayaccueil', hash: hash})
      }
      this.localAuthorityActive = false
    },
    goToPage(route) {
      for (let i = 0; i < this.mainTabs.length; i++) {
        this.mainTabs[i].active = false
      }
      for (let i = 0; i < this.hortilioToolPages.length; i++) {
        this.hortilioToolPages[i].route === route ? this.hortilioToolPages[i].active = true : this.hortilioToolPages[i].active = false
      }
      for (let i = 0; i < this.hortilioOtherPages.length; i++) {
        this.hortilioOtherPages[i].route === route ? this.hortilioOtherPages[i].active = true : this.hortilioOtherPages[i].active = false
      }
      if (this.$route.name !== route)
        this.$router.push({name: route})

      this.localAuthorityActive = false
    },
    administrate() {
      this.$router.push('/local-authorities/admin')
      this.localAuthorityActive = true
    },
    getImage(localAuth) {
      return localAuth.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + localAuth.photo_url + "&size=350"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getImageDescription(localAuth) {
      return "Image de " + localAuth.name
    }
  }
}
</script>
