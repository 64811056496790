<template>
  <layout-vertical class="jaya-layout">

    <router-view ref="childComponent"/>

    <div slot="navbar" class="w-100">
      <div class="graphic-top">
        <transition name="slide-fade">

        </transition>
      </div>
      <JayaNavbar/>
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import JayaNavbar from "@/components/menu/JayaNavbar"

export default {
  components: {
    JayaNavbar,
    LayoutVertical,
  },
  data() {
    return {
      showBack: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    scroll(event) {
      this.showBack = !(window.scrollY > this.scrollPosition && this.$store.state.app.windowWidth < 768)
      this.scrollPosition = window.scrollY
      if (this.scrollPosition < 50) this.showBack = true
      if (this.$refs.childComponent) {
        this.$refs.childComponent.scroll(event);
      }

    },
  }
}
</script>

<style lang="scss">
.jaya-layout {
  .navbar {
    box-shadow: none !important;
    background-color: var(--primary) !important;
  }

  .header-navbar {
    transition: none;
  }

  .jaya-menu {
    color: black !important;
  }

  .graphic-top {
    top: 0 !important;
    overflow: visible;
    position: absolute;
    background: none;

    .leftside-triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-right: 96vw solid transparent;
      border-top: 64px solid var(--primary);
      opacity: 0.4;
      z-index: 942;
    }

    .rightside-triangle {
      position: absolute;
      width: 0;
      left:-20vw;
      height: 0;
      border-left: 125vw solid transparent;
      border-top: 48px solid var(--primary);
      opacity: 0.4;
      z-index: 942;
    }

    .leftside-triangle-background {
      position: absolute;
      width: 0;
      height: 0;
      border-right: 96vw solid transparent;
      border-top: 64px solid var(--light);
      opacity: 1 !important;
      z-index: 941;
    }

    .rightside-triangle-background {
      position: absolute;
      width: 0;
      left:-20vw;
      height: 0;
      border-left: 125vw solid transparent;
      border-top: 48px solid var(--light);
      opacity: 1 !important;
      z-index: 941;
    }

    .triangles-background {
      position: absolute;
      width: 100vw;
      height: 66px;
      background-color: var(--light) !important;
      z-index: 941;
      opacity: 1 !important;
    }

    .transparent-triangle {
      opacity: 0 !important;
    }
  }
}
</style>
